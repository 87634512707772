@import 'styles/dimensions.scss';

.container {
  margin: 50px auto 75px;
  max-width: $maxContentWidth;
  padding: 0 30px;
}

.withSubnavigation {
  margin-top: 106px;
}
