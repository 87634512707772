@import 'styles/colors.scss';

.container {
  align-items: center;
  display: flex;
  flex: 0 1 100%;
}

.input {
  border-radius: 3px;
  border: 1px solid $lightGray;
  box-shadow: inset 0 0 1px $lightGray;
  color: $blueGray;
  display: block;
  flex: 0 1 100%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  padding: 12px;

  &::placeholder {
    color: rgba(67, 90, 111, 0.47);
  }

  &:read-only {
    background-color: #edeff2;
  }

  &:invalid {
    border-color: red;
  }
}
