.container {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
  padding: 30px 0;

  div {
    flex: 0 1 100%;
    justify-content: space-around;
    margin: 25px 0;

    button {
      margin-left: 30px;
    }

    button:first-child {
      margin-left: 0;
    }
  }

  p:first-child {
    font-size: 26px;
    flex: 0 1 100%;
    margin: 0;
  }
}
