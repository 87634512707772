@import 'styles/colors.scss';

.container {
  align-items: center;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 10px 20px;
  user-select: none;
  white-space: nowrap;
  width: fit-content;
}

.container:focus {
  outline: 0;
}

.iconOnlyContainer {
  padding: 6px 10px 9px 10px;
}

.iconOnlyContainer > *:first-child {
  font-size: 16px;
  position: relative;
  top: 2px;
}

/* Intents */
.danger-square,
.default-square {
  background: linear-gradient(0deg, #f4f5f7 0%, #ffffff 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67, 90, 111, 0.09), inset 0 0 0 1px rgba(67, 90, 111, 0.14);
}

.danger-square {
  color: $danger;
}

.default-square {
  color: $default;
}

/**
 * None of the hover and active states are included in the design. I followed Material UI's pattern, where:
 * - hover: display a darker color
 * - active: display a lighter, washed out color.
 */
.danger-square:hover,
.default-square:hover {
  background: linear-gradient(0deg, #c7c8ca 0%, #f4f5f7 100%);
}

.danger-square:active,
.default-square:active {
  background: unset;
  background-color: #eeeeee;
}

.primary-square {
  background: linear-gradient(0deg, #116ab8 0%, #0788de 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67, 90, 111, 0.2), inset 0 0 0 1px rgba(0, 34, 66, 0.2);
  color: #ffffff;
}

.primary-square:hover {
  background: linear-gradient(0deg, #0d5391 0%, #116ab8 100%);
}

.primary-square:active {
  background: unset;
  background-color: #5294ce;
}

.success-square {
  background: linear-gradient(180deg, #23c277 0%, #399d6c 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67, 90, 111, 0.14), inset 0 0 0 1px rgba(23, 54, 39, 0.2);
  color: #ffffff;
}

.success-square:hover {
  background: linear-gradient(180deg, #399d6c 0%, #30875c 100%);
}

.success-square:active {
  background: unset;
  background-color: #7acaa2;
}

.primary-link {
  color: $primary;
}

.primary-link:hover {
  color: #0f5596;
}

.primary-link:active {
  color: #188fff;
}

.danger-link {
  color: $danger;
}

.danger-link:hover {
  color: #790a07;
}

.danger-link:active {
  color: #e6130b;
}

/* Types */
.square {
  border-radius: 3px;
}

.link {
  border: none;
  box-shadow: none;
  background: unset;
  background-color: transparent;
}

.link:hover {
  background: unset;
}

/* children's styles */
.text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.05px;
  margin: 0 10px;
}

.iconWithText {
  /* When the button has both icon and text, use this to align them properly; otherwise, the icon will be higher up. */
  display: inline-flex;
}

.container:disabled {
  background: unset;
  background-color: #f5f6f7;
  box-shadow: inset 0 -1px 1px 0 rgba(67, 90, 111, 0.09), inset 0 0 0 1px rgba(67, 90, 111, 0.14);
  color: $disabled;
  cursor: default;
}
