@import 'styles/dimensions.scss';
@import 'styles/mixins.scss';

.container {
  align-items: center;
  display: flex;
}

.horizontal {
  background-color: white;
  box-shadow: 1px 0 1px 0 rgba(67, 90, 111, 0.41);
  height: 56px;
}

.horizontal > * {
  width: unset;
}

.vertical {
  flex-direction: column;
}

.fixed {
  margin-bottom: 156px;
  position: fixed;
  width: 100%;
  z-index: 2;

  @include narrowScreen {
    top: 0;
  }
}
