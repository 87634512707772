@import 'styles/colors.scss';

.container  {
  padding-bottom: 12px;
  max-width: 95%;
}

.errorMessage {
  height: 18px;
  padding-top: 6px;
}

.repeater {
  border-bottom: 1px solid rgba(189, 196, 202, 0.4);
  margin: 20px 0;
  flex: 0 1 100%;
  flex-flow: row wrap;
  display: flex;

  &:last-child {
    border: none;
  }
}
