@import 'styles/colors.scss';

.modalContainer {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}

.modalContent {
  background-color: #fefefe;
  border-radius: 6px;
  box-shadow: 0 0 20px 3px $lightGray;
  margin: 15vh auto;
  padding: 20px;
  width: 40%;
}

.alignTop {
  margin: 10vh auto;
}

.close {
  background: transparent;
  border: none;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  cursor: pointer;
  text-decoration: none;
}
