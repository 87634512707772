@import 'styles/colors.scss';
@import 'styles/dimensions.scss';

.container {
  display: flex;
  flex-flow: row wrap;
  max-width: $maxContentWidth;
  margin: 25px auto;
  padding: 25px;
}

.container > h3 {
  color: $gray;
  flex: 1 0 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}

.container .pane {
  flex: 0 1 50%;
}

.container > .heading {
  flex: 0 1 100%;
  max-height: 50px;
}

.container > button {
  align-items: center;
  border-radius: 5px;
  border: 1px solid lightgray;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
  transition: 0.25s;
  user-select: none;
  white-space: nowrap;
  width: fit-content;
}

.container > button:hover {
  background-color: lightgreen;
}
