@import 'styles/colors.scss';

.container > button {
  background: unset;
  background-color: #ffffff;
  border-radius: 23px;
  box-shadow: inset 0 -1px 1px 0 rgba(67, 90, 111, 0.09), inset 0 0 0 1px rgba(67, 90, 111, 0.14);
  color: $default;
}

/* Icon */
.container span:first-child {
  fill: $success;
}

.container > button:hover {
  background: unset;
  background-color: #dcf3e8;
}

.container > button:active {
  background: unset;
  background-color: #c6fae1;
}
