@import 'styles/colors.scss';

.container {
  display: inline-flex;
}

/* Intents */
.danger {
  fill: $danger;
}

.default {
  fill: #5b6d7f;
}

.disabled {
  fill: $disabled
}

.primary {
  fill: $primary;
}

.secondary {
  fill: #9ea0a5;
}

/* Use this when it's in a button to make it white */
.withinButton {
  fill: white;
}
