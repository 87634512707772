@import 'src/styles/colors';
@import 'src/styles/dimensions';
@import 'src/styles/mixins';

.container {
  margin: 0;
  padding: 0;
  max-width: 100%;
  background-color: #fefefe;
}

.firstRow {
  border-bottom: 1px solid #efefef;
  display: block;
}

.filename {
  color: $primary;
}

.errorMessage {
  color: $danger;
  margin: 0 0 10px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.title {
  color: rgb(42, 67, 94);
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 15px 0;
}

.horizontal {
  display: flex;
  flex-flow: row nowrap;

  div[class*="Column"] {
    margin: 0;
  }
}

.oneThird {
  flex: 0 1 33%;
  margin: 5px 5px 0;
}

.twoThirds {
  flex: 0 1 67%;
  margin-top: 5px;
  overflow-y: auto;
}

.dropzone {
  background-color: #f7f7f7;
  border: 1px dashed $lightGray;
  display: inline-block;
  margin: 0 auto;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;

  img {
    max-width: 70px;
  }
}

.stagedFiles {
  background-color: #f7f7f7;
  border: 1px solid $lightGray;
  max-height: 40vh;
  overflow-y: auto;

  .horizontal & {
    max-height: 16vh;
    font-size: 12px;
  }
}

.previewHolder {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;

  &:nth-child(odd) {
    background-color: white;
  }

  .preview {
    height: 30px;
    margin-right: 25px;
    object-fit: scale-down;
    width: 30px;
  }

  .previewImage {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    flex: 0 1 60%;
  }

  .select {
    position: relative;

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid $lightGray;
      box-shadow: inset 0 0 1px $lightGray;
      box-sizing: border-box;
      color: $blueGray;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      margin: 0;
      max-width: 100%;
      padding: 5px 12px;
      width: 100%;

      &::-ms-expand {
        display: none;
      }

      &:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
      }

      option {
        font-weight: normal;
      }
    }

    span {
      position: absolute;
      right: 3px;
      top: 5px;
    }
  }

  p {
    margin: 0;
  }
}

.actions {
  align-items: center;
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-top: 10px;
  text-align: left;

  button:first-child {
    margin-right: 15px;
  }
}
