@import 'styles/dimensions.scss';

.container {
  background-color: white;
  box-shadow: 0 0 1px 0 #435a6fe8;
  text-align: left;

  > div > div {
    padding-right: 15px;
  }

  img {
    max-height: 47px;
  }
}
