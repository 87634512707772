@import 'styles/colors.scss';
@import 'styles/dimensions.scss';

$dropdownWidth: 337px;

.container {
  align-items: center;
  position: relative;
  display: flex;
}

.imageContainer {
  border-radius: 100%;
  flex: 0 1 20%;
  margin: 20px 20px 0 0;
  max-height: 115px;
  width: 115px;

  &.rounded {
    overflow: hidden;
  }

  img {
    max-width: 120px;
  }
}

.imagePlaceholder {
  background-color: $lightGray;
  flex: 0 1 20%;
  height: 115px;
}

.popup {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  max-height: $dropdownWidth;
  overflow-y: scroll;
  padding: 0 10px 0 10px;
  position: absolute;
  text-align: left;
  top: 0;
  width: 280px;
  z-index: 2;
}

.popupWithPreview {
  border-radius: 5px 0 0 5px;
  height: $dropdownWidth;
  max-height: unset;
}

.row {
  box-shadow: inset 0 -1px 0 0 rgba(67, 90, 111, 0.14);
  font-size: 12px;
  margin: 0 -10px;
  padding: 10px;
}

.row > *:first-child {
  margin-right: 8px;
}

.titleHolder {
  height: 74px;
}

.titleRow {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.title {
  color: $darkBlue;
  font-size: 14px;
  font-weight: 600;
}

.closeIcon {
  color: $gray;
  font-size: 12px;
}

.filter {
  background-color: #fafafa;
  color: $primary;
  font-size: 12px;
}

.filter > *:first-child {
  font-size: 10px;
}

.previewContainer {
  background-color: white;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  margin-left: 301px;
  position: absolute;
  top: 0;
  z-index: 2;
}
