@import 'styles/colors.scss';

.container {
  /* Use the same width and padding-top value to keep the aspect ratio 1:1 */
  color: $darkBlue;
  height: 0;
  overflow: hidden;
  position: relative;
}

.background {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 90%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 90%;
}

/* Each size is 150% of the smaller one */
.small {
  font-size: 10px;
  padding-top: 36px;
  width: 36px;
}

/* Which is 150% the size of small */
.middle {
  font-size: 15px;
  padding-top: 54px;
  width: 54px;
}

.large {
  font-size: 22.5px;
  padding-top: 81px;
  width: 81px;
}

.jumbo {
  font-size: 33.75px;
  padding-top: 121.5px;
  width: 121.5px;
}

.image {
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
