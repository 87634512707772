.container {
  align-items: center;
  background-color: #fafafa;
  box-shadow: inset 0 -1px 0 0 rgba(67, 90, 111, 0.14);
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin: 0 -10px;
  padding: 10px;
}

.container > *:first-child {
  color: #425a70;
  font-size: 10px;
  margin-right: 8px;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  flex: 0 1 25px;
  margin: 0 -8px 0 0;
}

.input {
  background-color: transparent;
  border: none;
  color: #425a70;
  flex: 1 1 auto;
  font-size: 12px;
}

.input::placeholder {
  color: #425a70;
}

.input:focus {
  outline: 0;
}
