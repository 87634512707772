@import 'styles/dimensions.scss';
@import 'styles/mixins.scss';

.container {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 #435a6f78;
  margin-bottom: 25px;
}

.defaultSpacing {
  margin: 30px auto;
  max-width: $maxContentWidth;
  padding: 15px 5px;
}
