@import 'styles/colors.scss';

.container {
  margin: 0 auto;
  padding: 15px;
  max-width: 60vw;

  h2 {
    color: $blueGray;
    font-size: 26px;
    text-align: center;
  }

  p {
    color: $gray;
    font-size: 16px;
    text-align: center;
  }

  img {
    max-height: 40vh;
  }
}
