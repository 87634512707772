@import 'styles/colors.scss';

/* For navigations links */
.container {
  border-radius: 5px;
  color: #667788;
  display: block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.05px;
  margin: 0 15px;
  padding: 10px 20px;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.container:hover {
  background-color: #1070ca1a;
  color: $primary;
  text-decoration: none;
}

.container:active {
  background-color: rgba(16, 112, 202, 0.623);
  color: white;
}

/* For React Router's NavLink. Same as .container:hover */
.onThisRoute {
  background-color: #1070ca1a;
  color: $primary;
}

/* For external links */
.external {
  color: $primary;
  text-decoration: none;

  &:hover {
    color: lighten($primary, 20);
    text-decoration: underline;
  }
}

/* For internal links */
.internal {
  color: $primary;
  text-decoration: none;

  &:hover {
    color: lighten($primary, 20);
  }
}
