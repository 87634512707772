@import 'styles/colors.scss';

.firstLine {
  align-items: center;
  display: flex;
  font-size: 12px;
  height: 15px;
  padding-bottom: 3px;
}

.appName {
  color: #66788a;
  text-decoration: none;
}

.rightArrow {
  margin: 0 7px;
  opacity: 0.5;
}

.page {
  text-decoration: none;
  color: $darkBlue;
}

.secondLine {
  color: $darkBlue;
  font-size: 24px;
  text-transform: capitalize;

  a {
    color: $darkBlue;
    text-decoration: none;
  }
}
