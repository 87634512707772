.container > div {
  display: flex;
  margin-bottom: 10px;

  input {
    flex: 1 0 95%;
  }
}

.container > div > button {
  margin-left: 15px;
}

.container > div:last-child {
  justify-content: flex-end;
}
