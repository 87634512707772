@import 'styles/dimensions.scss';
@import 'styles/mixins.scss';

.container {
  align-items: center;
  background-color: white;
  box-shadow: 0 0 1px 0 #435a6f69;
  display: flex;
  height: $topbarHeight;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 4;

  @include mobileScreen {
    display: block;
    height: fit-content;
    position: static;
  }
}

.logoContainer {
  align-items: center;
  box-shadow: 1px 1px 0 0 #e4e7eb;
  cursor: pointer;
  display: flex;
  flex: 0 0 $sidebarWidth;
  height: 100%;
  justify-content: flex-start;
  text-decoration: none;

  @include mobileScreen {
    display: none;
  }
}

.logo {
  height: 55%;
  margin-left: 27px;
}

.rightSection {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.breadcrumbContainer {
  position: relative;
  left: 30px;
}

.breadcrumb {
  flex: 0 1 200px;
  margin: auto 20px;
  white-space: nowrap;

  @include mobileScreen {
    display: none;
  }
}

.rightIcons {
  flex: 0 1 auto;
  white-space: nowrap;
  margin: auto 20px;

  @include mobileScreen {
    float: right;
    padding: 5px 15px;
  }
}

.buttonArea {
  @include mobileScreen {
    float: left;
    padding: 5px 25px;
  }
}

.clearfix {
  @include mobileScreen {
    clear: both;
  }
}

.searchBar {
  @include mobileScreen {
    height: fit-content;
    padding: 10px 0;
  }
}
