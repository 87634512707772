@import 'styles/colors.scss';

$blue: #094b82;

.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  user-select: none;
  width: max-content;
  flex-flow: row wrap;
}

.input {
  /* Hide the native checkbox, but don't disable listeners */
  opacity: 0;
  position: absolute;
}

/* Custom checkbox's box */
.checkbox {
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid $lightGray;
  display: flex;
  height: 14px;
  margin-right: 12px;
  transition: all 0.3s ease-out;
  width: 14px;
}

/* The tick inside the box. It's a rectangle rotated 45 degree, showing only its 2 adjacent borders. */
.checkbox::after {
  /* For animations to work, 'border' property must come before 'border-width'. Same below. */
  border: solid #009bff;
  border-radius: 5px;
  border-width: 0 3px 3px 0;
  content: '';
  height: 0;
  left: 7px;
  opacity: 1;
  position: absolute;
  top: 7px;
  transform: rotate(0deg) scale(0);
  transition: all 0.3s ease-out;
  width: 0;
}

.input:checked ~ .checkbox {
  background-color: #0f71c1;
  border-radius: 3px;
  border: 1px solid $blue;
  opacity: 1;
  transform: rotate(0deg) scale(1);
}

.input:checked ~ .checkbox::after {
  background-color: transparent;
  border: solid #ffffff;
  border-radius: 0;
  border-width: 0 2px 2px 0;
  height: 7px;
  left: 4px;
  opacity: 1;
  top: 1px;
  transform: rotate(45deg) scale(1);
  width: 4px;
}

.text {
  color: $darkBlue;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}
