@import 'styles/colors.scss';

.container {
  position: relative;
}
.container select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid $lightGray;
  box-shadow: inset 0 0 1px $lightGray;
  box-sizing: border-box;
  color: $blueGray;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  max-width: 100%;
  padding: 13.5px 12px;
  width: 100%;
}
.container select::-ms-expand {
  display: none;
}
.container select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.container select option {
  font-weight: normal;
}

.container span {
  position: absolute;
  right: 15px;
  top: 12px;
}
