@import 'styles/colors.scss';
@import './Dropdown.module.scss';

$previewWidth: 300px;

.container {
  border-radius: 0 5px 5px 0;
  display: flex;
  flex-direction: column;
  height: $dropdownWidth;
  width: $previewWidth;
}

.title {
  background-color: #ffffff;
  border-radius: 0 5px 0 0;
  box-shadow: inset 0 -1px 0 0 rgba(67, 90, 111, 0.14);
  color: $darkBlue;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.img {
  border-radius: 0 0 5px 0;
  height: $previewWidth;
  object-fit: scale-down;
  width: $previewWidth;
}

.icon {
  align-items: center;
  color: #697888;
  display: flex;
  font-size: 70px;
  height: $previewWidth;
  justify-content: center;
}
