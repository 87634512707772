@import 'styles/colors.scss';

.small {
  font-size: 12px;
}

.medium {
  font-size: 15px;
}

.large {
  font-size: 18px;
}

.jumbo {
  font-size: 24px;
}

.default {
  color: $default;
}

.success {
  color: $success;
}

.danger {
  color: $danger;
}
