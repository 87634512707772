@import 'styles/dimensions.scss';

.container {
  position: relative;
  display: flex;
  height: 100vh;
}

@media (max-width: $mobileBreakpoint) {
  .container {
    display: block;
  }
}
