.container {
  flex: 1;
  margin-bottom: 25px;
  text-align: right;
}

.fixed {
  background-color: rgba(160, 170, 185, 0.7);
  border-radius: 5px 5px 0 0;
  bottom: 0;
  justify-content: space-between;
  left: 50%;
  margin: 0;
  max-height: 0;
  position: fixed;
  transition: 0.5s;

  button {
    margin: 0 100px;
  }
}

.fixed.show {
  max-height: 85px;
  padding: 25px 0px;
  transition: 0.5s;
}
