.circle {
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.35s;
  transform-origin: 50% 50%;
}

.circleContainer {
  top: 300px;
  position: relative;
}

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  overflow: auto;
  position: fixed;
  background-color: #00000066;
}
