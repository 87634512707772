@import 'styles/colors.scss';

.container {
  align-items: center;
  box-shadow: inset 0 -1px 0 0 rgba(67, 90, 111, 0.14);
  color: $default;
  display: flex;
  font-size: 12px;
  margin: 0 -10px;
  padding: 10px;
  user-select: none;
}

.container:focus {
  outline: 0;
}

.container:hover {
  background-color: #ecf4fb;
  color: $primary;
  font-weight: 600;
}

.icon {
  display: inline-block;
  height: 18px;
  margin-right: 8px;
}

.hidden {
  visibility: hidden;
}
