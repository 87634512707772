@import 'styles/dimensions.scss';
@import 'styles/mixins.scss';

.container {
  box-shadow: 0 0 1px 0 #435a6f69;
  display: flex;
  flex-direction: column;
  flex: 0 1 $sidebarWidth;
  justify-content: space-between;
  padding-top: 95px; /* 72px for the topbar's height; 23px for offsetting the nav links */
  text-align: left;
  z-index: 3;

  @include mobileScreen {
    padding-top: 0;
  }
}

.topSection {
  align-items: center;
  border-bottom: 1px solid #dfe3e8;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding-bottom: 20px;
}

.linkContainer {
  width: 75%;
}
