@import 'dimensions.scss';

@mixin maxScreen($maxWidth) {
  @media screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin narrowScreen {
  @include maxScreen($narrowDesktop) {
    @content;
  }
}

@mixin mobileScreen {
  @include maxScreen($mobileBreakpoint) {
    @content;
  }
}
